<!--
 * @Author: zhongxq
 * @Date: 2023-10-20 15:13:26
 * @LastEditors: zhongxq
 * @LastEditTime: 2023-10-25 21:55:45
 * @FilePath: \beijing-peoples-art-museum\src\views\pc\performance\components\detail\VideoList.vue
 * @Description: 视频集锦
 *
-->

<template>
  <div v-if="list?.length" class="list-card video-list">
    <TitleCom title="视频集锦" />
    <div
      v-for="item in list"
      :key="item.id"
      class="list-item"
      @click="handleDetail(item)"
    >
      <div class="left">
        <c-image :src="item.image" />
      </div>
      <div class="right">
        <div class="info">
          <p class="line-name">
            {{ item.title }}
          </p>
          <p class="line-info">
            <c-image
              :src="require('@/assets/img/performance/reloadtime@2x.png')"
            />
            <span>{{ dayjs(item.publishDate).format("YYYY-MM-DD") }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import TitleCom from "./TitleCom.vue";
export default {
  components: { TitleCom },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dayjs,
    };
  },
  methods: {
    // 查看详情
    handleDetail(item) {
      window.open(`#/culture/video/detail/${item.id}`, "_blank");
    },
  },
};
</script>

<style lang="less" scoped>
@import url("~@/assets/style/mixin.less");
.video-list {
  padding: 1.5625vw;
  background-color: #ffffff;
  .list-item {
    display: flex;
    margin-top: 1.0417vw;
    cursor: pointer;
    .left {
      flex-shrink: 0;
      width: 4.6875vw;
      height: 6.7708vw;
      margin-right: 1.0417vw;
      .img {
        width: 100%;
        height: 100%;
        display: block;
      }
    }
    .right {
      box-sizing: border-box;
      height: 6.7708vw;
      flex: 1;
      padding: 0.5208vw 0;
      text-align: left;
      .info {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .line {
          &-name {
            // margin-top: 10px;
            font-size: 0.9375vw;
            font-weight: 800;
            color: #212122;
            .text-overflow(2);
          }
          &-tag {
            // margin-top: 12px;
            display: flex;
            .tag {
              font-size: 0.625vw;
              font-weight: 400;
              color: #212122;
              background: #f8d39f;
              border-radius: 0.1042vw;
              height: 1.0417vw;
              line-height: 1.0417vw;
              padding: 0 0.4167vw;
              margin-right: 0.625vw;
            }
          }
          &-info {
            // margin-top: 12px;
            display: flex;
            align-items: center;
            font-size: 0.7292vw;
            font-weight: 400;
            color: #909399;
            .img {
              width: 0.8333vw;
              height: 0.8333vw;
              margin-right: 0.3125vw;
            }
          }
        }
      }
    }
  }
}
</style>
