<!--
 * @Author: zhongxq
 * @Date: 2023-10-20 13:32:13
 * @LastEditors: zhongxq
 * @LastEditTime: 2023-10-29 18:37:13
 * @FilePath: \beijing-peoples-art-museum\src\views\pc\performance\components\detail\MainInfo.vue
 * @Description: 剧目信息
 *
-->
<template>
  <div class="main-info">
    <div class="left">
      <c-image :src="detail.image" alt="" />
      <span v-if="detail.tag" class="tag">{{ detail.tag?.split(",")[0] }}</span>
    </div>
    <div class="right">
      <div class="info">
        <p class="line-name overflow-ellipsis">{{ detail.repertoireName }}</p>
        <p class="line-tag">
          <span class="tag">{{ detail.performerName }}</span>
        </p>
        <p class="line-info">
          <span>演出剧场：{{ detail.theatresName }}</span>
          <span>编剧：{{ writer }}</span>
        </p>
        <p class="line-info mt-14">
          <span>背景年代：{{ detail.centuryName }}</span>
          <span>导演：{{ director }}</span>
        </p>
        <p class="line-hr"></p>
        <p class="line-info">演出日期：{{ detail.showTime }}</p>
        <template v-if="!hideTicket">
          <p class="line-info mt-10">
            <span class="time">时间说明：{{ detail.timeDesc }}</span>
          </p>
          <p class="line-hr"></p>
          <div class="line-price">
            <span>票价：</span>
            <p class="price">{{ detail.price }}</p>
          </div>

          <div class="btn-box">
            <button @click="handleShowDialog(1)">剧目评分</button>
            <button v-if="detail.actors?.length" @click="handleShowDialog(2)">
              演员评分
            </button>
          </div>
        </template>
        <button
          v-if="!hideTicket && detail.canbuy"
          class="btn-buy"
          @click="handleBuy"
        >
          购票
        </button>
      </div>
    </div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="30%"
      top="20vh"
      append-to-body
      destroy-on-close
    >
      <div v-if="type === 1" class="single-score">
        <p class="title">请点击星星评分</p>
        <el-rate
          v-model="value"
          :max="10"
          show-score
          score-template="{value}分"
        ></el-rate>
      </div>
      <div v-else class="multi-score">
        <div v-for="item in detail.actors" :key="item.id" class="actor-score">
          <div class="left">
            <c-image :src="item.image" />
            <div>
              <p>{{ item.name }}</p>
              <p>饰：{{ item.role }}</p>
            </div>
          </div>
          <div class="right">
            <el-rate
              v-model="valueObj[`${item.id}`]"
              :max="5"
              allow-half
            ></el-rate>
            <span>{{ valueObj[`${item.id}`] * 2 }}</span>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleSubmit">提 交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { repertoireScore, entertainerScore } from "@/service/performance";
export default {
  props: {
    detail: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      title: "剧目打分",
      dialogVisible: false,
      value: 0,
      type: 1,
      loading: false,
      valueObj: {},
    };
  },
  computed: {
    // 从剧目典藏进入
    fromDc() {
      return this.$route.query.from === "dc";
    },
    hideTicket() {
      return this.fromDc;
    },
    writer() {
      let arr = this.detail.team || [];
      // arr = arr.filter((item) => item.role === "编剧").map((item) => item.name);
      arr = [
        ...new Set(
          arr.filter((item) => item.role === "编剧").map((item) => item.name)
        ),
      ];
      return arr.join(",");
    },
    director() {
      let arr = this.detail.team || [];
      // arr = arr.filter((item) => item.role === "导演").map((item) => item.name);
      arr = [
        ...new Set(
          arr.filter((item) => item.role === "导演").map((item) => item.name)
        ),
      ];
      return arr.join(",");
    },
  },
  methods: {
    handleBuy() {
      window.open(process.env.VUE_APP_TICKET_URL, "_blank");
    },
    handleShowDialog(type) {
      this.type = type;
      this.title = type === 1 ? "剧目打分" : "演员打分";
      this.value = 0;
      this.valueObj = {};
      this.detail.actors?.map((item) => {
        this.$set(this.valueObj, `${item.id}`, 0);
      });
      this.dialogVisible = true;
    },
    async handleSubmit() {
      if (this.type === 1) {
        if (!this.value) {
          this.$message.warning("请点击星星评分");
          return;
        }
        this.loading = true;
        try {
          await repertoireScore({ id: this.detail.id, score: this.value });
          this.$message.success("评分成功");
          this.dialogVisible = false;
        } catch (e) {
          console.log(e);
        } finally {
          this.loading = false;
        }
      } else {
        const keys = Object.keys(this.valueObj).filter(
          (key) => this.valueObj[key] > 0
        );
        if (keys.length === 0) {
          this.$message.warning("请点击星星评分");
          return;
        }
        this.loading = true;
        let apis = [];
        keys.forEach((key) => {
          const api = entertainerScore({
            id: key,
            score: this.valueObj[key] * 2,
          });
          apis.push(api);
        });
        try {
          await Promise.all(apis);
          this.$message.success("评分成功");
          this.dialogVisible = false;
        } catch (e) {
          console.log(e);
        } finally {
          this.loading = false;
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.main-info {
  box-sizing: border-box;
  padding: 2.6042vw;
  height: 22.3958vw;
  background-color: #ffffff;
  display: flex;
  position: relative;
  .left {
    flex-shrink: 0;
    width: 11.6667vw;
    height: 17.1875vw;
    margin-right: 1.5625vw;
    position: relative;
    .img {
      width: 100%;
      height: 100%;
      display: block;
    }
    .tag {
      position: absolute;
      left: 0.5208vw;
      top: 0.5208vw;
      height: 1.0417vw;
      background: #bf805f;
      border-radius: 0.1042vw;
      line-height: 1.0417vw;
      font-size: 0.625vw;
      color: #ffffff;
      padding: 0 0.4167vw;
    }
  }
  .right {
    flex: 1;
    .mt-10 {
      margin-top: 0.5208vw;
    }
    .mt-14 {
      margin-top: 0.7292vw;
    }
    .info {
      text-align: left;
      padding-right: 4.6875vw;
      .line {
        &-name {
          font-size: 1.0417vw;
          font-weight: 800;
          color: #212122;
        }
        &-tag {
          margin: 0.625vw 0 1.0417vw 0;
          display: flex;
          .tag {
            font-size: 0.625vw;
            font-weight: 400;
            color: #212122;
            background: #f8d39f;
            border-radius: 0.1042vw;
            height: 1.0417vw;
            line-height: 1.0417vw;
            padding: 0 0.4167vw;
            margin-right: 0.625vw;
          }
        }
        &-info {
          display: flex;
          font-size: 0.7292vw;
          line-height: 0.7292vw;
          color: #212122;
          span {
            display: inline-block;
            width: 9.1667vw;
            font-size: 0.625vw;
            font-weight: 400;
            color: #909399;
            & + span {
              margin-left: 0.5208vw;
            }
          }
          .time {
            width: auto;
          }
        }
        &-price {
          display: flex;
          font-size: 0.7292vw;
          line-height: 0.8333vw;
          color: #212122;
          span {
            flex-shrink: 0;
          }
          .price {
            line-height: 1.0417vw;
            color: var(--primary-color);
            font-weight: bold;
          }
        }
        &-hr {
          margin: 1.0417vw 0;
          width: 100%;
          height: 0.0521vw;
          background: #f4f4f4;
        }
      }
    }
    .btn-box {
      display: flex;
      margin-top: 1.5625vw;
      button {
        width: 5.8333vw;
        height: 1.7708vw;
        background: #f8f8fa;
        border-radius: 0.2083vw;
        border: none;
        outline: none;
        font-size: 0.7292vw;
        color: #212122;
        margin-right: 1.5625vw;
        cursor: pointer;
      }
    }
    .btn-buy {
      position: absolute;
      right: 2.6042vw;
      top: 3.2813vw;
      background-color: var(--primary-color);
      color: #ffffff;
      width: 4.375vw;
      height: 1.7708vw;
      font-size: 0.7292vw;
      border-radius: 0.2083vw;
      outline: none;
      border: none;
      cursor: pointer;
    }
  }
}
.single-score {
  .title {
    font-size: 0.9375vw;
    margin-bottom: 0.5208vw;
  }
}
.multi-score {
  max-height: 50vh;
  overflow-y: auto;
  text-align: left;
  .actor-score {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.7292vw;
    & + .actor-score {
      margin-top: 0.5208vw;
    }
    .left {
      display: flex;
      align-items: center;
      .img {
        width: 4.1667vw;
        height: 4.1667vw;
        margin-right: 0.5208vw;
        border-radius: 0.1042vw;
        flex-shrink: 0;
      }
      p {
        margin: 0.2604vw 0;
      }
    }
    .right {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      span {
        display: inline-block;
        width: 1.0417vw;
      }
    }
  }
}
</style>
