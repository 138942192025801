<!--
 * @Author: zhongxq
 * @Date: 2023-10-20 13:23:06
 * @LastEditors: zhongxq
 * @LastEditTime: 2023-10-20 13:29:10
 * @FilePath: \beijing-peoples-art-museum\src\views\pc\performance\components\TitleCom.vue
 * @Description: 标题
 * 
-->
<template>
  <div class="custom-title">
    <span>{{ title }}</span>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.custom-title {
  text-align: left;
  padding-left: 0.6771vw;
  font-size: 0.9375vw;
  font-weight: 800;
  color: #212122;
  line-height: 0.9375vw;
  position: relative;
  &::before {
    content: "";
    display: inline-block;
    width: 0.1563vw;
    height: 0.8333vw;
    background: #21424b;
    position: absolute;
    left: 0;
    top: 0.1042vw;
  }
}
</style>
