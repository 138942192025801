<!--
 * @Author: zhongxq
 * @Date: 2023-10-20 14:56:44
 * @LastEditors: zhongxq
 * @LastEditTime: 2023-10-23 16:42:40
 * @FilePath: \beijing-peoples-art-museum\src\views\pc\performance\components\detail\RecommendedList.vue
 * @Description: 推荐剧目
 *
-->

<template>
  <div v-if="list.length" class="list-card recommended-list">
    <TitleCom :title="title" />
    <div
      v-for="item in list"
      :key="item.id"
      class="list-item"
      @click="handleDetail(item)"
    >
      <div class="left">
        <c-image :src="item.image" />
      </div>
      <div class="right">
        <div class="info">
          <p class="line-name">{{ item.repertoireName }}</p>
          <p class="line-tag">
            <span class="tag">{{ item.performerName }}</span>
          </p>
          <p class="line-info">
            <c-image :src="require('@/assets/img/performance/bank@2x.png')" />
            <span>{{ item.theatresName }}</span>
          </p>
          <p class="line-info">
            <c-image
              :src="require('@/assets/img/performance/reloadtime@2x.png')"
            />
            <span>{{ item.showTime }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { nearSelling, sameName } from "@/service/performance";
import TitleCom from "./TitleCom.vue";
export default {
  components: { TitleCom },
  props: { id: String },
  data() {
    return {
      form: {
        status: 1,
        showTop: 1,
      },
      pager: {
        pageNo: 1,
        pageSize: 3,
      },
      list: [],
    };
  },
  created() {
    this.getData();
  },
  computed: {
    // 从剧目典藏进入
    fromDc() {
      return this.$route.query.from === "dc";
    },
    title() {
      return this.fromDc ? "历史演变" : "推荐剧目";
    },
  },
  methods: {
    async getData() {
      try {
        let res = null;
        if (this.fromDc) {
          res = await sameName(this.id);
        } else {
          res = await nearSelling();
        }
        this.list = res?.data || [];
      } catch (e) {
        this.list = [];
      }
    },
    handleDetail(item) {
      window.open(
        this.fromDc
          ? `#/culture/collection/detail/${item.id}?from=dc`
          : `#/performance/detail/${item.id}`,
        "_blank"
      );
      // this.$router.push({
      //   path: this.fromDc
      //     ? `/culture/collection/detail/${item.id}`
      //     : `/performance/detail/${item.id}`,
      //   query: {
      //     from: this.fromDc ? "dc" : "",
      //   },
      // });
    },
  },
};
</script>

<style lang="less" scoped>
@import url("~@/assets/style/mixin.less");
.recommended-list {
  padding: 1.5625vw;
  background-color: #ffffff;
  .list-item {
    display: flex;
    margin-top: 1.0417vw;
    cursor: pointer;
    .left {
      flex-shrink: 0;
      width: 4.6875vw;
      height: 6.7708vw;
      margin-right: 1.0417vw;
      .img {
        width: 100%;
        height: 100%;
        display: block;
      }
    }
    .right {
      box-sizing: border-box;
      height: 6.7708vw;
      flex: 1;
      padding: 0.5208vw 0;
      text-align: left;
      .info {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .line {
          &-name {
            // margin-top: 10px;
            font-size: 0.9375vw;
            font-weight: 800;
            color: #212122;
            .text-overflow(2);
          }
          &-tag {
            // margin-top: 12px;
            display: flex;
            .tag {
              font-size: 0.625vw;
              font-weight: 400;
              color: #212122;
              background: #f8d39f;
              border-radius: 0.1042vw;
              height: 1.0417vw;
              line-height: 1.0417vw;
              padding: 0 0.4167vw;
              margin-right: 0.625vw;
            }
          }
          &-info {
            // margin-top: 12px;
            display: flex;
            align-items: center;
            font-size: 0.7292vw;
            font-weight: 400;
            color: #909399;
            .img {
              width: 0.8333vw;
              height: 0.8333vw;
              margin-right: 0.3125vw;
            }
          }
        }
      }
    }
  }
}
</style>
