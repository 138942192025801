<template>
  <div class="list-card media-item" @click="handleDetail">
    <div class="left">
      <c-image :src="item.image" alt="" />
    </div>
    <div class="right">
      <div class="info">
        <p class="line-name">{{ item.title }}</p>
        <p class="line-from">
          来源：{{ item.source }}
          {{ dayjs(item.createTime).format("YYYY-MM-DD") }}
        </p>
        <p class="line-info" v-html="content"></p>
        <c-image
          class="btn-img"
          :src="require('@/assets/img/performance/right.png')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      dayjs,
    };
  },
  computed: {
    content() {
      let str = this.item.body || "";
      str = str.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/g, "");
      return this.addFontSizeToUlTags(str);
    },
  },
  methods: {
    handleDetail() {
      window.open(`#/ArtsInformation/detail?id=${this.item.id}`, "_blank");
    },
    addFontSizeToUlTags(html) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, "text/html");
      const ulTags = doc.querySelectorAll("span");
      const pTags = doc.querySelectorAll("p");

      ulTags.forEach(function (span) {
        span.style.fontSize = "0.9375vw";
      });
      pTags.forEach(function (p) {
        p.style.fontSize = "0.9375vw";
      });

      return doc.documentElement.innerHTML;
    },
  },
};
</script>

<style lang="less" scoped>
@import url("~@/assets/style/mixin.less");
.media-item {
  display: flex;
  cursor: pointer;
  .left {
    flex-shrink: 0;
    width: 15vw;
    height: 9.4792vw;
    margin-right: 1.5625vw;
    .img {
      width: 100%;
      height: 100%;
      display: block;
      border-radius: 0.2083vw;
    }
  }
  .right {
    box-sizing: border-box;
    height: 9.4792vw;
    flex: 1;
    text-align: left;
    position: relative;
    .info {
      .line {
        &-name {
          margin-top: 0.5208vw;
          font-size: 1.0417vw;
          font-weight: 800;
          color: #212122;
          .text-overflow(1);
        }
        &-from {
          margin-top: 0.8333vw;
          font-size: 0.625vw;
          color: #909399;
        }
        &-info {
          margin-top: 1.0417vw;
          font-size: 0.6771vw;
          color: #606266;
          line-height: 1.0417vw;
          .text-overflow(2);
        }
      }
      .btn-img {
        width: 3.0208vw;
        height: 0.7813vw;
        position: absolute;
        bottom: 0.5208vw;
        left: 0;
      }
    }
  }
}
</style>
